<!--@INFO изменить цвета переливания можно в <animate> атрибут values-->
<!--@INFO values="#0C30EB; #1DAB10; #CB0A0A; #9917AE; #0C30EB" для цветного лоадера-->
<script setup lang="ts">
</script>
<template>
  <path d="M58.7636 32C61.6556 32 64.0436 29.6403 63.5725 26.7869C63.175 24.3792 62.5024 22.0192 61.5641 19.7541C59.956 15.8717 57.5989 12.3441 54.6274 9.37258C51.6559 6.40111 48.1283 4.04401 44.2459 2.43585C41.8457 1.44169 39.3391 0.745794 36.7821 0.35933C34.1609 -0.0368308 32 2.14903 32 4.8C32 7.45097 34.173 9.5486 36.7634 10.1123C38.0628 10.3951 39.3376 10.7937 40.5721 11.3051C43.2898 12.4308 45.7592 14.0808 47.8392 16.1608C49.9192 18.2408 51.5692 20.7102 52.6949 23.4279C53.2622 24.7974 53.6907 26.2165 53.9763 27.6639C54.4428 30.0283 56.3537 32 58.7636 32Z" fill="url(#paint0_linear_9124_94851)" />
  <g filter="url(#filter0_dd_10755_75171)">
    <circle cx="59" cy="27.5" r="2.5" fill="url(#paint1_radial_10755_75171)" />
  </g>
  <defs>
    <filter
      id="filter0_dd_10755_75171"
      x="54.5"
      y="23"
      width="9"
      height="9"
      filterUnits="userSpaceOnUse"
      color-interpolation-filters="sRGB"
    >
      <feFlood flood-opacity="0" result="BackgroundImageFix" />
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
      <feOffset />
      <feGaussianBlur stdDeviation="0.5" />
      <feComposite in2="hardAlpha" operator="out" />
      <feColorMatrix type="matrix" values="0 0 0 0 0.710486 0 0 0 0 0.745299 0 0 0 0 0.922847 0 0 0 0.5 0" />
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_10755_75171" />
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
      <feOffset />
      <feGaussianBlur stdDeviation="1" />
      <feComposite in2="hardAlpha" operator="out" />
      <feColorMatrix type="matrix" values="0 0 0 0 0.710486 0 0 0 0 0.745299 0 0 0 0 0.922847 0 0 0 0.25 0" />
      <feBlend mode="normal" in2="effect1_dropShadow_10755_75171" result="effect2_dropShadow_10755_75171" />
      <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_10755_75171" result="shape" />
    </filter>
    <linearGradient
      id="paint0_linear_9124_94851"
      x1="25.3333"
      y1="-10"
      x2="64"
      y2="32"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0.307767" stop-color="white" stop-opacity="0" />
      <stop offset="1" stop-color="#9917AE">
        <animate
          attributeName="stop-color"
          values="#8C92AE; #8C92AE; #8C92AE; #8C92AE; #8C92AE"
          keyTimes="0; 0.25; 0.5; 0.75; 1"
          dur="2.5s"
          repeatCount="indefinite"
        />
      </stop>
    </linearGradient>
    <radialGradient
      id="paint1_radial_10755_75171"
      cx="0"
      cy="0"
      r="1"
      gradientUnits="userSpaceOnUse"
      gradientTransform="translate(59 27.5) rotate(90) scale(2.5)"
    >
      <stop stop-color="white" />
      <stop offset="0.947917" stop-color="#E6E9FA" />
    </radialGradient>
  </defs>
</template>
